import React from "react";

import { useGetLabelTranslation, useFilteredLabels, useValidateRTL } from "../../utils/hooks/useCommon";
import {
  PRODUCT_AVAILABLE_LABELS,
  PRODUCT_PRE_LABEL,
} from "../../utils/constants";

const ProductHeader = ({ product, productName, imageBaseUrl, productKey }) => {
  const isRTL = useValidateRTL();
  const featuresLabels = useFilteredLabels(
    PRODUCT_AVAILABLE_LABELS.feature,
    product.i18nLabels
  ).filter((label) => !label.includes(PRODUCT_AVAILABLE_LABELS.icon));
  const featuresIcons = useFilteredLabels(
    PRODUCT_AVAILABLE_LABELS.featureIcon,
    product.i18nLabels
  ).filter((label) => label.includes(PRODUCT_AVAILABLE_LABELS.icon));
  return (
    <>
      <div className="flex flex-row gap-1 items-center">
        <h1 className="flex-1 text-2xl font-bold">
          <span className="font-medium">
            {productName.split(" ").slice(0, 1).join(" ")}
          </span>
          <br />
          <span className="font-bold">
            {productName.split(" ").slice(1).join(" ")}
          </span>
        </h1>
        <img
          src={`${imageBaseUrl}${useGetLabelTranslation(
            product.productId,
            "image",
            product.labels
          )}`}
          alt={product.productId}
          style={{ height: "168px", width: "auto" }}
        />
      </div>
      <div className={`flex flex-col gap-2 ${isRTL ? 'rtl' : ''}`}>
        <div className="flex flex-row gap-2">
          <div className="flex-1">
            {/* FEATURES */}
            {featuresLabels.length > 0 && (
              <div>
                {featuresLabels.map((label, index) => (
                  <div className="flex flex-row gap-2 space-between my-2 pb-1 border-b border-gray-300">
                    <div className="flex-2">
                      <span className="text-sm" key={index}>
                        {
                          product.labels[
                            `${PRODUCT_PRE_LABEL}${productKey}_${label}`
                          ]
                        }
                      </span>
                    </div>
                    <div className="flex-1 flex justify-end">
                      <div className="flex flex-row gap-2">
                        <>
                          {featuresIcons.filter((icon) => icon.includes(label))
                            .length > 1
                            ? featuresIcons
                                .filter((icon) => icon.includes(label))
                                .map((icon, index) => {
                                  return (
                                    <img
                                      src={`${imageBaseUrl}${
                                        product.labels[
                                          `${PRODUCT_PRE_LABEL}${productKey}_${icon}`
                                        ]
                                      }`}
                                      alt={icon}
                                      style={{ height: "15px" }}
                                    />
                                  );
                                })
                            : featuresIcons
                                .filter((icon) => icon.includes(label))
                                .map((icon, index) => {
                                  return (
                                    <img
                                      src={`${imageBaseUrl}${
                                        product.labels[
                                          `${PRODUCT_PRE_LABEL}${productKey}_${icon}`
                                        ]
                                      }`}
                                      alt={icon}
                                      style={{ height: "20px" }}
                                    />
                                  );
                                })}
                        </>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductHeader;
