import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import Toast from "../components/common/Toast";
import { setTitle } from "../redux/slices/designSlice";
import { setError, setOtpRequestId, setIsLoggedIn } from "../redux/slices/userSlice";
import { setMsisdn, getIp, postRequestOTP } from "../redux/slices/userSlice";
import MainMenu from "../components/MainMenu";
import { useValidateRTL } from "../utils/hooks/useCommon";
import Register from "../components/login/Register";

const Login = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isRTL = useValidateRTL();
  const navigate = useNavigate();
  const { ip, error } = useSelector((state) => state.user);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("error");
  // ---THis part is for dev only---
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userTestIp = searchParams.get("usertestip");
  // --------------------
  useEffect(() => {
    dispatch(setTitle(`${t("menu.login")}`));
    dispatch(setMsisdn(""));
    dispatch(setOtpRequestId(""));
    dispatch(setIsLoggedIn(false));
  }, [dispatch, t]);

  useEffect(() => {
    dispatch(getIp());
  }, [dispatch]);

  const handleBack = () => {
    navigate(-1);
  };

  const onConfirm = async (phone) => {
    try {
      dispatch(setMsisdn(phone));
      const response = await dispatch(
        postRequestOTP({
          endpointFk: process.env.REACT_APP_ENDPOINT_FK,
          landingFk: process.env.REACT_APP_LANDING_FK,
          userId: phone,
          requestId: uuidv4(),
          password: process.env.REACT_APP_PASSWORD,
          userIpAddr: userTestIp || ip,
        })
      ).unwrap();
      if (response.otpRequestId) {
        navigate("/verification");
      }
    } catch (err) {
      console.log(err);
      setToastType("error");
      dispatch(setError(JSON.stringify(err)));
      setToastMessage(JSON.stringify(err));
      setShowToast(true);
    }
  };

  return (
    <div className="w-full">
      <MainMenu showSidebar={false} showLogin={false} />
      <Toast
        type={toastType}
        message={toastMessage}
        onClose={() => setShowToast(false)}
        visible={showToast}
      />
      <Register
        onConfirm={onConfirm}
        handleBack={handleBack}
        isRTL={isRTL}
        t={t}
      />
    </div>
  );
};

export default Login;
