import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Card from "../common/Card";
import { useFilteredLabels, useValidateRTL } from "../../utils/hooks/useCommon";
import "./styles.css";
import {
  AVAILABLE_STEPS,
  PRODUCT_AVAILABLE_LABELS,
  PRODUCT_PRE_LABEL,
} from "../../utils/constants";

const OfferComponent = ({
  selectedProduct,
  productId,
  productKey,
  imageBaseUrl,
}) => {
  const { t } = useTranslation();
  const isRTL = useValidateRTL();
  const navigate = useNavigate();
  const [selectedMonths, setSelectedMonths] = useState(null);
  const [selectedDevices, setSelectedDevices] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);

  // get Steps of current product type
  const getSteps = () => {
    const steps = AVAILABLE_STEPS.filter((step) =>
      productId.toLowerCase().includes(step.productId.toLowerCase())
    );
    return steps;
  };
  // Get months periodicity available for current product
  const monthsPeriodicityAvailable = selectedProduct.offers
    .map((offer) => {
      return offer.months;
    })
    .filter((month, index, self) => self.indexOf(month) === index);
  // Get devices available for current product
  const devicesAvailable = selectedProduct.offers
    .map((offer) => {
      return offer.devices;
    })
    .filter((device, index, self) => self.indexOf(device) === index);

  useEffect(() => {
    setSelectedDevices(devicesAvailable.length === 1 && devicesAvailable[0]);
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedMonths && selectedDevices) {
      const offers = selectedProduct.offers.filter((offer) => {
        return (
          offer.months === selectedMonths && offer.devices === selectedDevices
        );
      });
      setSelectedOffer(offers[0]);
    }
  }, [selectedMonths, selectedDevices]);

  // Handlers
  const handleChangeMonths = (e) => {
    setSelectedMonths(parseInt(e.target.value));
  };
  const handleChangeDevices = (value) => {
    setSelectedDevices(parseInt(value));
  };

  const featuresLabels = useFilteredLabels(
    PRODUCT_AVAILABLE_LABELS.feature,
    selectedProduct.i18nLabels
  ).filter((label) => !label.includes(PRODUCT_AVAILABLE_LABELS.icon));
  const featuresIcons = useFilteredLabels(
    PRODUCT_AVAILABLE_LABELS.featureIcon,
    selectedProduct.i18nLabels
  ).filter((label) => label.includes(PRODUCT_AVAILABLE_LABELS.icon));
  return (
    <div className="flex flex-col gap-4 items-center justify-center text-center">
      <h1 className="text-2xl font-bold uppercase">{t("offer.selectOffer")}</h1>
      <div className="flex flex-col gap-4 w-full">
        {getSteps().map((step) => (
          <React.Fragment key={step.id}>
            {step.name === "months" && (
              <Card title={t(step.title)} step={step.id}>
                <div
                  className={`flex flex-col gap-2 items-start mx-3 my-3 ${
                    isRTL ? "rtl" : ""
                  }`}
                >
                  {monthsPeriodicityAvailable.map((month, index) => (
                    <label
                      key={`${month}-${index}`}
                      className="flex items-center justify-start my-1"
                    >
                      <input
                        type="radio"
                        name="month"
                        value={month}
                        className="hidden"
                        onChange={handleChangeMonths}
                      />
                      <span className="w-6 h-6 border-2 border-black rounded-full flex items-center justify-center mr-2">
                        <span className="w-3 h-3 bg-primary rounded-full hidden"></span>
                      </span>
                      <span className={`${isRTL ? "mr-2" : "ml-2"} text-lg`}>
                        {month}
                      </span>
                      <span
                        className={`capitalize ${
                          isRTL ? "mr-1" : "ml-1"
                        } text-lg`}
                      >
                        {t("product.month")}
                      </span>
                    </label>
                  ))}
                </div>
              </Card>
            )}
            {step.name === "devices" &&
              devicesAvailable.length > 1 &&
              selectedMonths && (
                <Card title={t(step.title)} step={step.id}>
                  <div className={`flex flex-col items-center mx-3 my-3`}>
                    <div
                      className={`flex flex-row gap-4 items-center justify-center`}
                    >
                      {devicesAvailable.map((device, index) => (
                        <div
                          key={`${device}-${index}`}
                          className="flex items-center"
                        >
                          <input
                            type="radio"
                            name="device"
                            value={device}
                            className="hidden"
                            onChange={() => handleChangeDevices(device)}
                          />
                          <span
                            className={`w-12 h-12 border-2 text-2xl rounded-full flex items-center justify-center mr-2 cursor-pointer ${
                              selectedDevices === device
                                ? "bg-primary border-primary text-white"
                                : "border-black"
                            }`}
                            onClick={() => handleChangeDevices(device)}
                          >
                            <span className="text-2xl font-bold">{device}</span>
                          </span>
                        </div>
                      ))}
                    </div>
                    <div className="flex flex-row gap-2 mt-4">
                      {featuresLabels.length > 1
                        ? featuresIcons
                            .slice(1)
                            .map((icon, index) => (
                              <img
                                key={index}
                                src={`${imageBaseUrl}${
                                  selectedProduct.labels[
                                    `${PRODUCT_PRE_LABEL}${productKey}_${icon}`
                                  ]
                                }`}
                                alt={icon}
                                className="w-6 h-6"
                              />
                            ))
                        : featuresIcons.map((icon, index) => (
                            <img
                              key={index}
                              src={`${imageBaseUrl}${
                                selectedProduct.labels[
                                  `${PRODUCT_PRE_LABEL}${productKey}_${icon}`
                                ]
                              }`}
                              alt={icon}
                              className="w-6 h-6"
                            />
                          ))}
                    </div>
                  </div>
                </Card>
              )}
            {step.name === "price" && selectedMonths && selectedDevices && (
              <Card title={t(step.title)} step={step.id}>
                <div className="flex flex-row gap-2 items-center justify-center mx-3 my-3">
                  {isRTL ? (
                    <>
                      <h1 className="font-bold text-4xl text-primary">
                        {selectedOffer && t("product.currency")}
                      </h1>
                      <h1 className="font-bold text-4xl text-primary">
                        {selectedOffer &&
                          selectedOffer.price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                      </h1>
                    </>
                  ) : (
                    <>
                      <h1 className="font-bold text-4xl text-primary">
                        {selectedOffer &&
                          selectedOffer.price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                      </h1>
                      <h1 className="font-bold text-4xl text-primary">
                        {selectedOffer && t("product.currency")}
                      </h1>
                    </>
                  )}
                </div>
              </Card>
            )}
          </React.Fragment>
        ))}
        {/* <Card title={t("offer.selectOfferPeriod")} step={1}>
          <div className={`flex flex-col gap-2 items-start mx-3 my-3 ${isRTL ? "rtl" : ""}`}>
            {monthsPeriodicityAvailable.map((month, index) => (
              <label key={index} className="flex items-center justify-start my-1">
                <input
                  type="radio"
                  name="month"
                  value={month}
                  className="hidden"
                  onChange={handleChangeMonths}
                />
                <span className="w-6 h-6 border-2 border-black rounded-full flex items-center justify-center mr-2">
                <span className="w-3 h-3 bg-primary rounded-full hidden"></span>
              </span>
                <span className={`${isRTL ? "mr-2" : "ml-2"} text-lg`}>{month}</span>
                <span className={`capitalize ${isRTL ? "mr-1" : "ml-1"} text-lg`}>{t("product.month")}</span>
              </label>
            ))}
          </div>
        </Card>
        {
          selectedOffer && (
            <Card title={t("offer.selectOfferPrice")} step={2} styles="mt-3">
              <div className="flex flex-row gap-2 items-center justify-center mx-3 my-3">
                {
                  isRTL ? (
                    <>
                      <h1 className="font-bold text-4xl text-primary">{t("product.currency")}</h1>
                      <h1 className="font-bold text-4xl text-primary">
                        {selectedOffer.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                      </h1>
                    </>
                  ) : (
                    <>
                      <h1 className="font-bold text-4xl text-primary">{selectedOffer.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</h1>
                      <h1 className="font-bold text-4xl text-primary">{t("product.currency")}</h1>
                    </>
                  )
                }
              </div>
            </Card>
          )
        } */}
      </div>
      {/* buttons */}
      <div className="flex flex-col gap-2 items-center mx-4 mt-3 w-full">
        <button
          className={`bg-primary text-white px-4 py-2 rounded-lg w-full text-lg font-bold disabled:opacity-50 ${
            !selectedOffer ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={!selectedOffer}
        >
          {t("offer.select")}
        </button>
        <button
          className="bg-secondary text-white px-4 py-2 rounded-lg w-full text-lg font-bold"
          onClick={() => {
            navigate(-1);
          }}
        >
          {t("login.return")}
        </button>
      </div>
    </div>
  );
};

export default OfferComponent;
