import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { setError } from "../redux/slices/userSlice";
import { setTitle } from "../redux/slices/designSlice";
import MainMenu from "../components/MainMenu";
import { useValidateRTL } from "../utils/hooks/useCommon";
import CodeVerification from "../components/login/CodeVerification";
import { postRequestOTP, postValidateOTP } from "../redux/slices/userSlice";
import Toast from "../components/common/Toast";

const Verification = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { msisdn, isWrongCode, otpRequestId, ip, error } = useSelector(
    (state) => state.user
  );
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("error");

  const isRTL = useValidateRTL();
  const navigate = useNavigate();

  // ---THis part is for dev only---
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userTestIp = searchParams.get("usertestip");
  // --------------------

  useEffect(() => {
    dispatch(setTitle(`${t("menu.login")}`));
  }, [dispatch, t]);

  useEffect(() => {
    if (!msisdn) {
      navigate("/login");
    }
  }, [msisdn, navigate]);

  const handleBack = () => {
    navigate(-1);
  };

  const onConfirm = async (code) => {
    try {
      const response = await dispatch(
        postValidateOTP({
          otpRequestId: otpRequestId,
          otp: code,
          endpointFk: process.env.REACT_APP_ENDPOINT_FK,
          password: process.env.REACT_APP_PASSWORD,
        })
      ).unwrap();

      if (response?.authCode) {
        navigate("/");
      }
    } catch (error) {
      console.error("Error al validar OTP:", error);
      dispatch(setError(error));
      setToastType("error");
      setToastMessage(JSON.stringify(error));
      setShowToast(true);
    }
  };

  const getNewCode = () => {
    try {
      dispatch(
        postRequestOTP({
          endpointFk: process.env.REACT_APP_ENDPOINT_FK,
          landingFk: process.env.REACT_APP_LANDING_FK,
          userId: msisdn,
          requestId: uuidv4(),
          password: process.env.REACT_APP_PASSWORD,
          userIpAddr: userTestIp || ip,
        })
      ).unwrap();
      if (response.otpRequestId) {
        setToastType("success");
        setToastMessage("Code envoyé");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full">
      <MainMenu showSidebar={false} showLogin={true} />
      <Toast
        type={toastType}
        message={toastMessage}
        onClose={() => setShowToast(false)}
        visible={showToast}
      />
      <CodeVerification
        onConfirm={onConfirm}
        handleBackToHome={handleBack}
        getNewCode={getNewCode}
        isRTL={isRTL}
        isWrongCode={isWrongCode}
      />
    </div>
  );
};

export default Verification;
