import React, { useState } from "react";

import Card from "../common/Card";
import { useSelector } from "react-redux";
import Spinner from "../common/Spinner";

const Register = ({ onConfirm, handleBack, isRTL, t }) => {
  const { loading } = useSelector((state) => state.user);
  const [codes, setCodes] = useState(Array(5).fill(""));

  const handleChange = (index, value) => {
    if (index < 2) {
      if (value.length <= 3) {
        const newCodes = [...codes];
        newCodes[index] = value;

        setCodes(newCodes);

        // Mover el foco al siguiente input si se llena
        if (value.length === 3) {
          const nextInput = document.getElementById(`code-${index + 2}`);
          if (nextInput) {
            nextInput.focus();
          }
        }
        // Mover el foco al input anterior si se borra todo el contenido
        if (value.length === 0 && index > 0) {
          const previousInput = document.getElementById(`code-${index}`);
          if (previousInput) {
            previousInput.focus();
          }
        }
      }
    } else {
      if (value.length <= 2) {
        const newCodes = [...codes];
        newCodes[index] = value;

        setCodes(newCodes);

        // Mover el foco al siguiente input si se llena
        if (value.length === 2 && index < 4) {
          const nextInput = document.getElementById(`code-${index + 2}`);
          if (nextInput) {
            nextInput.focus();
          }
        }
        // Mover el foco al input anterior si se borra todo el contenido
        if (value.length === 0 && index > 0) {
          const previousInput = document.getElementById(`code-${index}`);
          if (previousInput) {
            previousInput.focus();
          }
        }
      }
    }
  };

  const isConfirmEnabled = codes.join("").length >= 12;

  return (
    <div className="flex flex-col items-center justify-center mt-8 mx-4">
      <Card title={t("login.set_phone")}>
        <div className="flex flex-col items-center justify-center mt-6 mx-4 mb-6">
          <div className="flex mb-2 space-x-0">
            {codes.map((code, index) => {
              return index === 0 ? (
                <div key={index}>
                  <label htmlFor={`code-${index + 1}`} className="sr-only">
                    {`${index + 1} code`}
                  </label>
                  <input
                    type="number"
                    maxLength="3"
                    id={`code-${index + 1}`}
                    value={code}
                    onChange={(e) => handleChange(index, e.target.value)}
                    className={`block w-16 h-14 py-3 text-2xl font-extrabold text-center text-white bg-lightgray placeholder:text-white placeholder:opacity-40 ${
                      index === 0 && "rounded-l-xl bg-primary"
                    }`}
                    required
                    placeholder="213"
                  />
                </div>
              ) : (
                <div key={index}>
                  <label htmlFor={`code-${index + 1}`} className="sr-only">
                    {`${index + 1} code`}
                  </label>
                  <input
                    type="number"
                    maxLength="2"
                    id={`code-${index + 1}`}
                    value={code}
                    onChange={(e) => handleChange(index, e.target.value)}
                    className={`block w-16 h-14 py-3 text-2xl font-extrabold text-center text-white bg-lightgray placeholder:text-white placeholder:opacity-40 ${
                      index === 0 && "rounded-l-xl bg-primary"
                    }`}
                    required
                    placeholder="00"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </Card>
      <div className="flex flex-col items-center text-center justify-center mt-6 mx-4 mb-6">
        <p className="text-sm mt-8">{t("login.pin_is_sent_to")}</p>
      </div>

      {/* Buttons */}
      <div className="flex-col fixed bottom-0 left-0 w-full  p-4 flex justify-between items-center">
        {loading ? (
          <div className="flex justify-center items-center mb-2">
            <Spinner />
          </div>
        ) : (
          <button
            onClick={
              isConfirmEnabled ? () => onConfirm(codes.join("")) : undefined
            }
            className={`bg-primary text-white px-4 py-2 rounded-lg w-full my-1 min-h-12 font-bold text-lg ${
              isConfirmEnabled ? "" : "opacity-50 cursor-not-allowed"
            }`}
            disabled={!isConfirmEnabled}
          >
            {t("login.confirm")}
          </button>
        )}
        <button
          onClick={handleBack}
          className="bg-secondary text-white px-4 py-2 rounded-lg w-full my-1 min-h-12 font-bold"
        >
          {t("login.return")}
        </button>
      </div>
    </div>
  );
};

export default Register;
