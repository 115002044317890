import { configureStore } from '@reduxjs/toolkit';
import designSlice from './slices/designSlice';
import userSlice from './slices/userSlice'; 
import productsSlice from './slices/productsSlice';
import userCookieMiddleware from './middleware/userCookieMiddleware';

export const store = configureStore({
  reducer: {
    design: designSlice,
    user: userSlice,
    products: productsSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(userCookieMiddleware),
});