import React from "react";
import { useValidateRTL } from "../../utils/hooks/useCommon";

const Card = ({ title = "", children, step, styles }) => {
  const isRTL = useValidateRTL();
  return (
    <div className={`flex flex-col items-center justify-center ${styles}`}>
      <div
        className={`border-primary border-2 rounded-xl w-full p-0 shadow-md shadow-gray-300`}
      >
        <div
          className="min-h-20 rounded-xl w-full shadow-md shadow-gray-300 flex items-center"
        >
          <div className="min-w-16 min-h-20 rounded-l-md bg-primary flex items-center justify-center">
            <span className="text-white font-bold text-3xl">{step}</span>
          </div>
          <div className={`flex flex-col mx-4 w-full ${isRTL ? "rtl text-right" : "text-left"}`}>
            <h1 className={`${isRTL ? "text-2xl " : "text-xl"} font-bold`}>
              {title}
            </h1>
          </div>
        </div>
        <div className="flex flex-col mt-6 mx-4 mb-6">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Card;
