import React from "react";
import { useTranslation } from "react-i18next";
import { useValidateRTL } from "../utils/hooks/useCommon";

const MainOffer = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const isRtl = useValidateRTL();
  const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  return (
    <div className="bg-customBlue p-4" style={{ minHeight: 260 }}>
      <div
        className="flex flex-col justify-center items-center h-full rounded-lg p-6 object-contain shadow-md"
        style={{
          backgroundColor: "#F2F3F5",
          backgroundImage: `url(${(`${imageBaseUrl}/bitdefender/images/mainOffer/mainOfferBackground.png`)})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <img
          src={(() => {
            try {
              return (`${imageBaseUrl}/bitdefender/images/mainOffer/mainOffer_${language}.png`);
            } catch (error) {
              return (`${imageBaseUrl}/bitdefender/images/mainOffer/mainOffer_en.png`);
            }
          })()}
          alt="OfferInfo"
          className="w-full h-full object-contain"
        />
        <div className={`w-full flex justify-start pt-2 ${isRtl ? 'rtl' : ''}`}>
          <button className="bg-customGreen text-white px-4 py-2 rounded-full hover:bg-green-600 shadow-md">
            {t("mainOffer.takeit")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainOffer;
